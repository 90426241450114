import Snackbar from "@mui/material/Snackbar/Snackbar";

export interface AlertType {
  success: "success";
  error: "error";
  warning: "warning";
  info: "info";
  default: "default";
}

type Props = {
  open: boolean;
  close: any;
  message: string | React.ReactNode;
  type?: "success" | "error" | "warning" | "info" | "default" | any;
  duration?: number;
};

export default function Alert({
  open,
  close,
  message,
  type = "default",
  duration = 3000,
}: Props) {
  const messageContainer = (children: React.ReactNode) => (
    <div className="flex items-center gap-x-3">{children}</div>
  );

  const messageContent = () => {
    if (type === "success") {
      return messageContainer(
        <>
          <img
            src="/memes/success-kid.png"
            className="w-[62px] h-[62px] min-w-[62px] rounded-full object-cover shadow-xl shadow-black/30"
          />
          <div className="grid gap-1 justify-start">
            <div className="text-left font-medium text-lg tracking-wide">
              Success!
            </div>
            {message}
          </div>
        </>
      );
    } else if (type === "error") {
      return messageContainer(
        <>
          <div className="grid gap-1">
            <div className="font-medium text-lg tracking-wide">Hmm...</div>
            {message}
          </div>
        </>
      );
    } else {
      return <div className="text-[16px]">{message}</div>;
    }
  };

  const gradientBackground =
    type === "success"
      ? "linear-gradient(135deg, #9D85FF, #7654FF, #9D85FF)"
      : type === "error"
      ? "linear-gradient(135deg, #ff758c, #ea335e)"
      : "linear-gradient(135deg, #7654FF, #7654FF)";

  return (
    <Snackbar
      open={open}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      onClose={close}
      autoHideDuration={duration}
      message={messageContent()}
      sx={{
        "& .MuiPaper-root": {
          background: gradientBackground,
          borderRadius: "8px",
          padding: "12px 28px",
        },
      }}
    />
  );
}
