import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
  children: React.ReactNode;
  width?: string;
  bottomBlend?: boolean;
};

export default function Modal({
  open,
  setOpen,
  children,
  width,
  bottomBlend = false,
}: Props) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        {/* Overlay */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-violet-900/20 backdrop-blur-[2px] transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-top mt-16 sm:mt-0 justify-center text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`${
                  width ? `w-full ${width}` : "w-full sm:w-[450px]"
                } relative transform  overflow-hidden sm:p-6 rounded-2xl bg-dark-default px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 ${
                  bottomBlend && "sm:pb-0"
                } neonPurple`}
              >
                <div
                  className="absolute top-4 z-10 cursor-pointer right-4 flex h-10 w-10 items-center justify-center rounded-md 
                  p-2 text-light-gray hover:bg-gray-50/10 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                </div>
                <div className="mt-4" />
                {children}
                {bottomBlend && (
                  <div className="bg-gradient-to-b w-full h-[150px] sticky -bottom-6 lg:-bottom-1 left-0 from-dark-default/0 to-dark-default" />
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
