"use client";

import { useRef, useState, FormEvent } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { create } from "zustand";
import Alert from "../Alert";
import DividerWithText from "../Divider/DividerWithText";
import PrimaryButton from "../Forms/Button/PrimaryBtn";
import SecondaryButton from "../Forms/Button/SecondaryBtn";
import TextArea from "../Forms/TextArea";
import Modal from "../Modal";
import TextTitle from "../Typography/TextTitle";

interface ContactModalState {
  openContact: boolean;
  setOpenContact: (openContact: boolean) => void;
}

export const useContactModalStore = create<ContactModalState>((set) => ({
  openContact: false,
  setOpenContact: (openContact) => set({ openContact }),
}));

interface ContactInfoCardProps {
  title: string;
  email: string;
  phone: string;
}

function ContactInfoCard({ title, email, phone }: ContactInfoCardProps) {
  return (
    <div className="w-full transition hover:brightness-125 rounded-xl bg-dark-default-1 px-5 p-3 grid items-center justify-center gap-2">
      <h3 className="text-base font-semibold leading-7 ">{title}</h3>
      <dl className="grid gap-1 mt-1  text-sm leading-6 text-light-gray">
        <div>
          <dt className="sr-only">Email</dt>
          <dd>
            <a
              className="font-semibold text-dark-pri hover:underline "
              href={`mailto:${email}`}
            >
              {email}
            </a>
          </dd>
        </div>
        <div className="">
          <dt className="sr-only">Phone number</dt>
          <dd>{phone}</dd>
        </div>
      </dl>
    </div>
  );
}

const CONTACT_CARDS = [
  {
    title: "Partnerships",
    email: "partners@rodeyo.com",
    phone: "+1 (225) 320-8005",
  },
  { title: "Press", email: "press@rodeyo.com", phone: "+1 (225) 320-8005" },
  {
    title: "Join our team",
    email: "careers@rodeyo.com",
    phone: "+1 (225) 320-8005",
  },
  { title: "Say hello", email: "hi@rodeyo.com", phone: "+1 (225) 320-8005" },
];

export default function ContactUs() {
  const { openContact, setOpenContact } = useContactModalStore();

  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [disabled, setDisabled] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [snackbar, setSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>("");
  const [alertType, setAlertType] =
    useState<"default" | "success" | "error">("default");

  const reRef = useRef<ReCAPTCHA>(null);

  const sendMessage = async () => {
    if (message.length < 4 || !email) {
      setSnackbarMessage("Please provide your email address and a message.");
      setSnackbar(true);
      return;
    }

    setDisabled(true);
    setLoading(true);

    try {
      const token = await reRef.current?.executeAsync();
      reRef.current?.reset();

      const body = JSON.stringify({
        email,
        body: message,
        subject: "Contact Form Submission: Rodeyo Website",
        token,
      });

      const response = await fetch("/api/sendEmail", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body,
      });
      const data = await response.json();

      if (data.success) {
        setOpenContact(false);
        setAlertType("success");
        setSnackbarMessage(
          "Message sent successfully! 🎉 We'll get back to you soon."
        );
        setSnackbar(true);
        setEmail("");
        setMessage("");
      } else {
        throw new Error("Message not sent successfully");
      }
    } catch (error) {
      setAlertType("error");
      setSnackbarMessage("Message failed to send. Please try again.");
      setSnackbar(true);
    } finally {
      setDisabled(false);
      setLoading(false);
    }
  };

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    sendMessage();
  };

  return (
    <>
      <Modal open={openContact} setOpen={setOpenContact} width="sm:w-[80%]">
        <div className="relative py-12">
          <div className="-z-10 rounded-full blur-[150px] bg-primary/30 w-[350px] h-[400px] absolute top-0 left-0 rotate-[20deg]" />
          <div className="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:max-w-none">
            <div className="w-full flex flex-col xl:flex-row gap-x-4 gap-y-10">
              <div className="sm:!min-w-[500px]">
                <TextTitle title="Send a Message" />
                <div className="w-full px-2 mt-9">
                  <form
                    onSubmit={handleSubmit}
                    className="grid gap-y-[18px] w-full"
                  >
                    <input
                      id="email"
                      placeholder="mostly@harmless.com"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      type="email"
                      name="email"
                      disabled={disabled}
                      autoFocus
                      className="w-full flex-1 outline-none rounded-md border-2 border-white/20 bg-white/10 px-3.5 py-2 text-white shadow-sm hover:border-dark-pri focus:border-dark-pri sm:leading-6"
                    />

                    <TextArea
                      placeholder="Life, the universe, and everything..."
                      onChange={(e) => setMessage(e.target.value)}
                      value={message}
                      disabled={disabled}
                    />

                    <PrimaryButton
                      loading={loading}
                      type="submit"
                      disabled={disabled}
                    >
                      Send
                    </PrimaryButton>
                  </form>

                  <DividerWithText text="or" />

                  <SecondaryButton
                    onClick={() => window.open("https://help.rodeyo.com/")}
                  >
                    Go to Forum
                  </SecondaryButton>
                </div>
              </div>

              <ReCAPTCHA
                size="invisible"
                sitekey={
                  process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY || ""
                }
                ref={reRef}
              />

              <div className="w-full md:grid-cols-2 grid gap-4 my-auto relative">
                {CONTACT_CARDS.map((contact, index) => (
                  <ContactInfoCard
                    key={index}
                    title={contact.title}
                    email={contact.email}
                    phone={contact.phone}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Alert
        open={snackbar}
        close={() => setSnackbar(false)}
        message={snackbarMessage}
        type={alertType}
      />
    </>
  );
}
