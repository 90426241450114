import React from "react";

type Props = {
  icon?: string;
  title: string;
};

export default function SectionHeading({ icon, title }: Props) {
  return (
    <div className="">
      <div className=" text-2xl text-left font-bold flex items-center gap-x-4">
        {icon && (
          <div className="flex bg-gradient-to-br  from-dark-purple to-dark-default items-center justify-center p-1 rounded-lg w-[40px] h-[40px]">
            {icon}
          </div>
        )}
        <span className="gradientTextGray">{title}</span>
      </div>
    </div>
  );
}
